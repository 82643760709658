import { Product } from 'constants/product';
import Cookies from 'js-cookie';

import GTM from 'modules/GTM';
import Location from 'modules/Location';
import { parseJwt } from 'utils/token';

declare const window: any;

export const FREE_PRODUCTS = [
  getProductName(Product.OpenWidget),
  getProductName(Product.Platform),
  getProductName(Product.TeamChat),
  getProductName(Product.PartnerProgram),
];

export const productsOrder = [
  getProductName(Product.LiveChat),
  getProductName(Product.ChatBot),
  getProductName(Product.HelpDesk),
  getProductName(Product.KnowledgeBase),
  getProductName(Product.OpenWidget),
  getProductName(Product.TeamChat),
  getProductName(Product.Platform),
  getProductName(Product.PartnerProgram),
];

const getProductByClientId = (clientId: string): Product => {
  switch (clientId) {
    case import.meta.env.VITE_APP_LC_CLIENT_ID:
      return Product.LiveChat;
    case import.meta.env.VITE_APP_HD_CLIENT_ID:
      return Product.HelpDesk;
    case import.meta.env.VITE_APP_KB_CLIENT_ID:
      return Product.KnowledgeBase;
    case import.meta.env.VITE_APP_CB_CLIENT_ID:
      return Product.ChatBot;
    case import.meta.env.VITE_APP_OW_CLIENT_ID:
      return Product.OpenWidget;
    case import.meta.env.VITE_APP_TP_CLIENT_ID:
      return Product.Platform;
    case import.meta.env.VITE_APP_TC_CLIENT_ID:
      return Product.TeamChat;
    case import.meta.env.VITE_APP_PP_CLIENT_ID:
      return Product.PartnerProgram;
    default:
      return Product.Undefined;
  }
};

export function getProductName(p: Product): string {
  switch (p) {
    case Product.LiveChat:
      return 'LiveChat';
    case Product.HelpDesk:
      return 'HelpDesk';
    case Product.KnowledgeBase:
      return 'KnowledgeBase';
    case Product.ChatBot:
      return 'ChatBot';
    case Product.OpenWidget:
      return 'OpenWidget';
    case Product.Platform:
      return 'Platform';
    case Product.TeamChat:
      return 'TeamChat';
    case Product.PartnerProgram:
      return 'PartnerProgram';
    case Product.Accounts:
      return 'Accounts';
    default:
      return 'Undefined';
  }
}

export const getProductDisplayName = (product: string, isExtendedName: boolean = false): string => {
  switch (product) {
    case 'Platform':
      return isExtendedName ? 'Text Platform' : 'Platform';
    case 'PartnerProgram':
      return 'Partner Program';
    default:
      return product;
  }
};

export function detectProduct(): Product {
  let detectedProduct = Product.Undefined;

  const hostname = Location.getReferrerHostname();
  const state = Location.getHashParam('state');
  const token = parseJwt(state);
  const clientId =
    Location.getHashParam('client_id') || Location.getUrlParam('client_id') || token?.client_id;
  const hasPanelClientId = clientId === import.meta.env.VITE_APP_CLIENT_ID;

  const product = Location.getHashParam('product');
  detectedProduct = Product[product] ?? Product.Undefined;

  if (detectedProduct === Product.Undefined) {
    detectedProduct = getProductByClientId(clientId);
  }

  if (detectedProduct === Product.Undefined) {
    if (/livechat(inc)?\.com$/.test(hostname) && /^accounts/.test(hostname) === false) {
      detectedProduct = Product.LiveChat;
    } else if (/helpdesk\.com$/.test(hostname)) {
      detectedProduct = Product.HelpDesk;
    } else if (/chatbot\.com$/.test(hostname)) {
      detectedProduct = Product.ChatBot;
    } else if (/knowledgebase\.ai$/.test(hostname)) {
      detectedProduct = Product.KnowledgeBase;
    } else if (/openwidget\./.test(hostname)) {
      detectedProduct = Product.OpenWidget;
    } else if (/platform\./.test(hostname)) {
      detectedProduct = Product.Platform;
    } else if (/teamchat\./.test(hostname)) {
      detectedProduct = Product.TeamChat;
    } else if (/partners\./.test(hostname)) {
      detectedProduct = Product.PartnerProgram;
    }
  }

  if (detectedProduct === Product.Undefined && !hasPanelClientId) {
    detectedProduct = parseInt(Cookies.get('product'), 10) || Product.Undefined;
  }

  const currentProduct = parseInt(Cookies.get('product'), 10) || Product.Undefined;

  if (currentProduct !== detectedProduct) {
    Cookies.set('product', detectedProduct, { expires: 365, sameSite: 'Lax' });
    GTM.track('virtualPageView', { productID: getProductName(detectedProduct) });
  }
  return detectedProduct;
}

export function defaultProduct(): void {
  Cookies.remove('product');
  window.location = window.location.origin;
}

export function defaultProductAndOrganization(): void {
  Cookies.remove('product');
  window.location = `${window.location.origin}?organization_id=choose`;
}

export const sortProducts = (products: string[]): string[] =>
  products.sort((a, b) => productsOrder.indexOf(a) - productsOrder.indexOf(b));

export const getProductByName = (product: string): number => {
  switch (product) {
    case 'LiveChat':
      return Product.LiveChat;
    case 'ChatBot':
      return Product.ChatBot;
    case 'HelpDesk':
      return Product.HelpDesk;
    case 'KnowledgeBase':
      return Product.KnowledgeBase;
    case 'OpenWidget':
      return Product.OpenWidget;
    case 'Platform':
      return Product.Platform;
    case 'TeamChat':
      return Product.TeamChat;
    case 'PartnerProgram':
      return Product.PartnerProgram;
    case 'Accounts':
      return Product.Accounts;
    default:
      return undefined;
  }
};

export const getAppLinkByName = (product: string): string => {
  switch (product) {
    case 'LiveChat':
      return import.meta.env.VITE_APP_LC_APP_URL;
    case 'ChatBot':
      return import.meta.env.VITE_APP_CB_APP_URL;
    case 'HelpDesk':
      return import.meta.env.VITE_APP_HD_APP_URL;
    case 'KnowledgeBase':
      return import.meta.env.VITE_APP_KB_APP_URL;
    case 'OpenWidget':
      return import.meta.env.VITE_APP_OW_APP_URL;
    case 'Platform':
      return import.meta.env.VITE_APP_TP_APP_URL;
    case 'TeamChat':
      return import.meta.env.VITE_APP_TC_APP_URL;
    case 'PartnerProgram':
      return import.meta.env.VITE_APP_PP_APP_URL;
    default:
      return undefined;
  }
};
