import { create } from 'zustand';

import { IKeyValue } from 'interfaces/keyvalue';

export type StoreValue = string | number | boolean | IKeyValue;

export interface IData {
  [key: string]: StoreValue;
}

export interface NestedIData {
  [key: string]: IData;
}

interface UseStore {
  data: IData;
  set: (newData: IData) => void;
  get: (key: string) => StoreValue | undefined;
  delete: (key: string) => void;
  flush: () => void;
  isEmpty: () => boolean;
  toObject: () => IData;
}

export const useBaseStore = create<UseStore>((set, get) => ({
  data: {},
  set: (newData) => set((state) => ({ data: { ...state.data, ...newData } })),
  get: (key) => get().data[key],
  delete: (key) =>
    set((state) => {
      const newData = { ...state.data };
      delete newData[key];
      return { data: newData };
    }),
  flush: () => set(() => ({ data: {} })),
  isEmpty: () => Object.keys(get().data).length === 0,
  toObject: () => ({ ...get().data }),
}));

// object version for non-component usage
export const baseStore = {
  set: (data: IData) => useBaseStore.getState().set(data),
  get: (key: string) => useBaseStore.getState().get(key),
  delete: (key: string) => useBaseStore.getState().delete(key),
  flush: () => useBaseStore.getState().flush(),
  isEmpty: () => useBaseStore.getState().isEmpty(),
  toObject: () => useBaseStore.getState().toObject(),
};
