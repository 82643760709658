// eslint-disable-next-line max-classes-per-file
import { getMobileOS, isUsingMobileApp } from 'helpers/device';

class Rule {
  agents: string[];
  params: {
    referrer: string;
  };

  withParams(params) {
    this.params = params;
    return this;
  }

  test() {
    return false;
  }

  run() {
    return {};
  }
}

class MobileAppRule extends Rule {
  test() {
    return isUsingMobileApp();
  }

  run() {
    return {
      utm_source: `mobile-app-for-${getMobileOS()}`,
      utm_medium: 'mobile-app',
      utm_campaign: 'in-app-signup',
    };
  }
}

class DesktopAppRule extends Rule {
  constructor() {
    super();

    this.agents = ['LiveChatSmartClient'];
  }

  test() {
    return this.agents.some((name) => navigator.userAgent.indexOf(name) !== -1);
  }

  run() {
    let agent;
    let position;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.agents.length; i++) {
      position = navigator.userAgent.indexOf(this.agents[i]);
      if (position !== -1) {
        agent = this.agents[i];
        break;
      }
    }

    const cut = navigator.userAgent.substr(position + agent.length);
    const result = /\d+\.\d+\.\d+/.exec(cut);

    const version = result.length ? result[0] : 'unknown-version';
    const platform = navigator.platform.indexOf('Mac') !== -1 ? 'mac' : 'windows';

    return {
      utm_source: `desktop-app-for-${platform}`,
      utm_medium: 'desktop-app',
      utm_campaign: 'in-app-signup',
      utm_content: version,
    };
  }
}

class ReferringWebsiteRule extends Rule {
  test() {
    if (this.params.referrer) {
      const referrer = new URL(this.params.referrer);
      const location = new URL(document.location.href);

      return referrer && referrer.hostname !== location.hostname;
    }

    return false;
  }

  run() {
    const referrer = new URL(this.params.referrer);
    const medium = referrer.protocol === 'android-app:' ? 'android-app' : 'referrer';

    return {
      utm_source: referrer.hostname.replace(/^(www\.)/, ''),
      utm_medium: medium,
    };
  }
}

class DirectEntryRule extends Rule {
  test() {
    return true;
  }

  run() {
    return {
      utm_source: 'direct',
    };
  }
}

export default function (params) {
  const utms = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'];

  // Set rules, order determines priority
  const rules = [
    new DesktopAppRule(),
    new MobileAppRule(),
    new ReferringWebsiteRule(),
    new DirectEntryRule(), // this rule should always be last
  ];

  // Run only if no UTMs are present
  const definedUtms = utms.filter((key) => params[key] !== undefined);
  if (definedUtms.length) {
    return {};
  }

  // Find first matching rule and execute it
  const rule = rules.find((ruleToFind) => ruleToFind.withParams(params).test());

  if (rule !== undefined) {
    return rule.run();
  }
  return {};
}
