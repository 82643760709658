export enum ProductDomain {
  LiveChat = 'www.livechat.com',
  KnowledgeBase = 'www.knowledgebase.com',
  HelpDesk = 'www.helpdesk.com',
  ChatBot = 'www.chatbot.com',
  OpenWidget = 'www.openwidget.com',
}

export enum Product {
  Undefined = 0,
  LiveChat,
  HelpDesk,
  KnowledgeBase,
  ChatBot,
  OpenWidget,
  Platform,
  TeamChat,
  PartnerProgram,
  Accounts,
}
