import { useEffect } from 'react';
import Cookies from 'js-cookie';

import {
  utmParams,
  sourceParams,
  baseTrackingParams,
  lcTrackingParams,
  cbTrackingParams,
  products,
  owTrackingParams,
} from 'constants/tracking';
import Location from 'modules/Location';
import UtmConcluder from 'modules/UtmConcluder';
import { getProductName, detectProduct } from 'helpers/product';
import { Product } from 'constants/product';

import { useBaseStore, IData, StoreValue, baseStore, NestedIData } from './base-store';

const allTrackingParams = []
  .concat(utmParams)
  .concat(sourceParams)
  .concat(baseTrackingParams)
  .concat(lcTrackingParams)
  .concat(cbTrackingParams)
  .concat(owTrackingParams);

interface UseSharedStore {
  set: (data: IData) => void;
  get: (key: string) => StoreValue | undefined;
  toObject: () => IData;
  delete: (key: string) => void;
  flush: () => void;
  multiGet: (keys: string[]) => Partial<IData>;
  readParams: () => void;
  addMissing: (data: IData) => void;
  forProduct: (product: string, autoDetect?: boolean) => IData;
  forAllProducts: () => NestedIData;
}

export const useSharedStore = (keys?: string[]): UseSharedStore => {
  const store = useBaseStore();

  useEffect(() => {
    readParams();
  }, []);

  const readParams = (): void => {
    const obj: IData = {};

    obj.environment = import.meta.env.VITE_APP_ENV;

    allTrackingParams.forEach((trackingParam) => {
      const value = Location.getUrlParam(trackingParam) || Cookies.get(trackingParam);

      if (value) {
        try {
          obj[trackingParam] = decodeURIComponent(value);
        } catch (e) {
          obj[trackingParam] = value;
        }
      }
    });

    if (keys?.length) {
      keys.forEach((param) => {
        const value = Location.getUrlParam(param) || Cookies.get(param);
        if (value) {
          try {
            obj[param] = decodeURIComponent(value);
          } catch (e) {
            obj[param] = value;
          }
        }
      });
    }

    const currentState = store.toObject();
    const hasChanges = Object.keys(obj).some((key) => obj[key] !== currentState[key]);

    if (hasChanges) {
      set(obj);
    }
  };

  const multiGet = (requestedKeys: string[]): Partial<IData> => {
    const result: Partial<IData> = {};
    requestedKeys.forEach((requestedKey) => {
      const value = store.get(requestedKey);
      if (value !== undefined) {
        result[requestedKey] = value;
      }
    });

    return result;
  };

  const set = (object: IData): void => {
    const data = { ...object };

    // Coupon cannot be set without promocode
    if ('coupon' in data && !('promocode' in data)) {
      delete data.coupon;
    }

    // Rename params from cookie/url naming convention
    // to API naming convention before saving
    const nameMappings = {
      promocode: 'promo_code',
      coupon: 'coupon_code',
      discount: 'discount_coupon',
    };

    Object.keys(data).forEach((key): void => {
      if (data[key] === undefined) {
        delete data[key];
        return;
      }

      if (Object.keys(nameMappings).includes(key)) {
        data[nameMappings[key]] = data[key];
        delete data[key];
      }
    });

    if (data.ghtests != null) {
      try {
        data.ghtests = JSON.parse(window.atob(String(data.ghtests)));
      } catch (e) {
        delete data.ghtests;
      }
    }

    store.set(data);
  };

  const addMissing = (data: IData) => {
    const currentData = store.toObject();

    const newData = Object.keys(data).reduce((acc, key) => {
      if (!(key in currentData)) {
        acc[key] = data[key];
      }

      return acc;
    }, {} as IData);

    if (Object.keys(newData).length > 0) {
      set({ ...currentData, ...newData });
    }
  };

  const forProduct = (product: string, autoDetect: boolean = true): IData => {
    if (!localStorage) {
      return {};
    }

    const productTracking = Object.keys(localStorage)
      .filter((key) => key.startsWith(product.toLowerCase()))
      .reduce((obj, key): any => {
        try {
          const parsedEntry = JSON.parse(localStorage[key]);
          if (parsedEntry.expire < Date.now()) {
            return obj;
          }

          const valueKey = key.split(':')[1];
          switch (valueKey) {
            case 'utm':
              return Object.assign(obj, parsedEntry.value);
            default:
              return Object.assign(obj, { [valueKey]: parsedEntry.value });
          }
        } catch (error) {
          console.error(`Malformed tracking object: ${localStorage[key]}, due: ${error}`);
          return obj;
        }
      }, {});

    if (autoDetect) {
      const detectedProduct = getProductName(detectProduct());

      // Handle tracking for the current product only
      if (detectedProduct !== product) {
        const updatedProductTracking = {
          ...productTracking,
        };

        if (product === getProductName(Product.LiveChat) && Object.keys(productTracking).length) {
          updatedProductTracking.enable_data_enrichment = '1';
        }

        return Object.assign(
          updatedProductTracking,
          Object.keys(productTracking).length ? UtmConcluder(productTracking) : {}
        );
      }
    }

    const trackingData = store.toObject();

    if (product === getProductName(Product.LiveChat)) {
      productTracking.enable_data_enrichment = '1';
    }

    delete trackingData.password;

    const combinedTracking = Object.assign(trackingData, productTracking);

    return Object.assign(combinedTracking, UtmConcluder(combinedTracking));
  };

  const forAllProducts = (): NestedIData => {
    const detectedProduct = detectProduct();

    return products.reduce((obj, key): any => {
      const trackingForProduct = forProduct(key);

      if (key === getProductName(Product.LiveChat) && detectedProduct !== Product.LiveChat) {
        return obj;
      }

      // Clean empty params
      let filteredTracking = {};

      Object.keys(trackingForProduct).forEach((param) => {
        const newVal = trackingForProduct[param];
        filteredTracking = newVal ? { ...filteredTracking, [param]: newVal } : filteredTracking;
      });

      if (Object.keys(filteredTracking).length !== 0) {
        return Object.assign(obj, { [key]: filteredTracking });
      }
      return obj;
    }, {});
  };

  return {
    set,
    get: (key) => store.get(key),
    toObject: () => store.toObject(),
    delete: (key) => store.delete(key),
    flush: () => store.flush(),
    multiGet,
    readParams,
    addMissing,
    forProduct,
    forAllProducts,
  };
};

// object version for non-component usage
export const sharedStore = {
  set: (data: IData) => baseStore.set(data),
  get: (key: string) => baseStore.get(key),
  delete: (key: string) => baseStore.delete(key),
  flush: () => baseStore.flush(),
  isEmpty: () => baseStore.isEmpty(),
  toObject: () => baseStore.toObject(),
};
