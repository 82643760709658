import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import Location from 'modules/Location';

declare const window: {
  dataLayer: any[];
};

class GTM {
  dataLayer = window.dataLayer || [];

  init(): void {
    if (!Location.isTrackingRouteBlocked()) {
      Analytics({
        app: 'accounts.livechatinc.com',
        plugins: [
          googleTagManager({
            containerId: `GTM-${import.meta.env.VITE_APP_GTM}`,
          }),
        ],
      });
    }
  }

  logEvent(login, event, opts = {}): void {
    this.dataLayer.push({ event, login, ...opts });
  }

  async track(event, opts = {}): Promise<any> {
    return new Promise((resolve): void => {
      const e = { ...opts, event, eventCallback: resolve };

      this.dataLayer.push(e);
    });
  }
}

export default new GTM();
