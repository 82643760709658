export const utmParams = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign'];
export const sourceParams = ['source_url', 'source_type', 'source_id'];

export const baseTrackingParams = [
  'referrer',
  'landing_page',
  'partner_id',
  'promocode',
  'sscid',
  'discount',
];

export const lcTrackingParams = [
  'coupon',
  'url' /* website  autofill */,
  'chat_purpose',
  'industry',
  'company_size',
  'country',
  'phone',
  'audience',
  'ghtests',
  'coupon_code',
  'promo_code',
  'discount_coupon',
  'region',
  'environment',
];

export const cbTrackingParams = [
  'onboardingLesson',
  'client_name',
  'partner_email',
  'purchase_order',
  '_state',
];

export const owTrackingParams = ['preconfig'];

export const products = [
  'LiveChat',
  'HelpDesk',
  'KnowledgeBase',
  'ChatBot',
  'OpenWidget',
  'TeamChat',
  'PartnerProgram',
];

export const GTMEvents = {
  GENERIC: 'generic',
  CATEGORIES: {
    ACCOUNTS: 'Accounts',
    INTERACTIONS: 'Interactions',
  },
  ACTIONS: {
    LOG_IN_FORM: 'Log In Form',
    RESET_PASSWORD: 'Reset Password Form',
    LOG_IN_WITH: 'Log In With',
    SIGN_UP_FREE: 'Sign up free (top right)',
    FORGOT_PASSWORD: 'Forgot Password',
    GO_BACK: 'Go back',
    LOGO_CLICK: 'Logo Click',
    SELECT_INPUT: 'Select Input',
    SIGN_UP: 'Sign Up Form',
    SIGN_UP_WITH: 'Sign Up With',
  },
  LABELS: {
    SENT: 'Sent',
    FAILED: 'Failed',
    MAIN_LOGO: 'Main Logo',
    SUB_HEADER: 'Subheader',
  },
};
